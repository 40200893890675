import React from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';
import { parse } from 'query-string';

import PublicLayout from '../layout/public-layout';
import VerifyEmailContainer from '../containers/auth/verify-email';
import SEO from '../components/seo';

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 30px;
`;

export default function VerifyEmail({ location }: RouteComponentProps<{}>) {
  const qs = location ? parse(location.search) : { linkId: '', userId: '' };

  const linkId: string = qs['linkId'] as string;
  const userId: string = qs['userId'] as string;

  return (
    <div>
      <SEO title="Sign In" />;
      <PublicLayout>
        <Container>
          <VerifyEmailContainer linkId={linkId} userId={userId} />
        </Container>
      </PublicLayout>
    </div>
  );
}
